
import { GameManage_GMSearchClientErrorLog } from '@/apis/game_manage';
import { ServerManage_GMIgnoreErrorLog } from '@/apis/server_manage';
import { formatJson } from "@/utils";
import { exportJson2Excel } from "@/utils/excel";
import { TodayEnd, TodayStart } from "@/utils/timer";
import { ElForm, ElMessage, ElMessageBox } from 'element-plus';
import { defineComponent, nextTick, onMounted, reactive, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({

  setup() {
    const { t } = useI18n()
    const dataForm = ref<typeof ElForm>()
    const dataMap = reactive({
      time: Date,
      tableKey: 0,
      list: [] as IClientErrorLog[],
      total: 1,
      listLoading: true,
      starttime: TodayStart(),
      endtime: TodayEnd(),

      listQuery: {
        page: 1,
        limit: 20,
        logLevel: "Error",
        title: "",
        label: "",
        process: "",
        sort: "+id"
      },
      dialogFormVisible: false,
      downloadLoading: false,
      tempLogRecord: { FullMsg: "" },
    })


    const handleCurrentChange = (page: number) => {
      dataMap.listQuery.page = page;
      getLogList();
    };
    const handleSizeChange = (val: any) => {
      // dataMap.getList(null, null, val)
    };
    const getLogList = async () => {
      dataMap.listLoading = true;
      dataMap.list = [];
      let msg = {} as IC2G_GMSearchClientErrorLog;
      msg.StartTime = Math.ceil(dataMap.starttime.getTime() / 1000);
      msg.EndTime = Math.ceil(dataMap.endtime.getTime() / 1000);
      msg.PageCount = dataMap.listQuery.limit;
      msg.PageIndex = dataMap.listQuery.page;
      if (dataMap.listQuery.title !== "") {
        msg.Title = dataMap.listQuery.title;
      }
      let cbmsg = await GameManage_GMSearchClientErrorLog(msg);
      if (cbmsg?.Error !== 0) {
        ElMessage.error(`GetLogFail: ${cbmsg?.Message}`);
        return;
      }
      if (cbmsg.SearchResult) {
        for (let k of cbmsg.SearchResult) {
          let record = JSON.parse(k) as IClientErrorLog;
          record.Id = record._id;
          if (record.Label === "") {
            record.Label = "{}";
          }
          let labelInfo = JSON.parse(record.Label);
          record.GameId = labelInfo.GameId || "";
          record.GameTime = labelInfo.GameTime || "";
          record.Index = dataMap.list.length + 1 + (dataMap.listQuery.page - 1) * dataMap.listQuery.limit;
          dataMap.list.push(record);
        }
      }
      dataMap.total = cbmsg.SearchCount;
      dataMap.listLoading = false;
    }

    const GetShotMsg = (row: IClientErrorLog) => {
      if (row == null || row.Msg == null) { return "" }
      let arr = row.Msg.split("\r\n");
      if (arr.length >= 3) {
        let i = 0;
        let r = "";
        while (i < 3) {
          r += arr[i] + "<br>";
          i++;
        }
        return r;
      } else {
        return row.Msg;
      }
    }
    const GetFullMsg = (row: IClientErrorLog) => {
      if (row == null || row.Msg == null) { return "" }
      let arr = row.Msg.split("\r\n");
      let i = 0;
      let r = "";
      while (i < arr.length) {
        r += arr[i] + "<br>";
        i++;
      }
      return r;
    }

    const handleSingleLogInfo = (row: IClientErrorLog) => {
      dataMap.tempLogRecord.FullMsg = GetFullMsg(row);
      dataMap.dialogFormVisible = true;
      nextTick(() => {
        (dataForm.value as any).clearValidate()
      });
    }
    const handleIgoneLog = (row: IClientErrorLog) => {
      ElMessageBox.confirm(
        `Confirm to Delete ErrorLog<${row.Id}> ?`,
        "Delete ErrorLog",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(async () => {
          let msg = {} as IC2G_GMIgnoreErrorLog;
          msg.LogId = row.Id;
          msg.LogTime = Math.ceil(parseInt(row.Time) / 1000);
          msg.LogProcess = row.Process;
          let cbmsg = await ServerManage_GMIgnoreErrorLog(msg);
          if (cbmsg?.Error == 0) {
            ElMessage.success(`Log<${row.Id}> Delete ErrorLog`)
            row.Level = 0;
          } else {
            ElMessage.error(`Log<${row.Id}> Delete ErrorLog`)
          }
        })
        .catch(err => {
          console.error(err);
        });
    }

    const handleDownload = () => {
      dataMap.downloadLoading = true;
      const tHeader = ["Id", "Time", "Level", "Label", "Msg"];
      const filterVal = ["Id", "Time", "Level", "Label", "Msg"];
      const data = formatJson(filterVal, dataMap.list);
      exportJson2Excel(tHeader, data, "GameError");
      dataMap.downloadLoading = false;
    }

    onMounted(() => {
      getLogList()
    })

    return {
      t, ...toRefs(dataMap),
      dataForm,
      getLogList,
      GetShotMsg,
      handleCurrentChange,
      handleSizeChange,
      handleSingleLogInfo,
      handleIgoneLog,
      handleDownload
    }
  }
})

